import React from "react";
import AppBar, { Spacer } from "../components/AppBar";
import AppBarContents from "./AppBarContents";
import Announcement from "./Announcement";
import ContentBox from "./ContentBox";

export default ({ children }) => {
  return (
    <>
      <AppBar>
        <AppBarContents />
      </AppBar>
      <Announcement />
      <Spacer />
      <main>
        <ContentBox direction="column">{children}</ContentBox>
      </main>
    </>
  );
};
