import styled from "styled-components";

export default styled.textarea`
  box-sizing: border-box;
  font-size: inherit;
  font-family: inherit;
  border: none;
  -webkit-appearance: none;
  background: transparent;
  color: inherit;
  padding: 11px;
  font-weight: 600;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.33);
  border-radius: 4px;
  width: 100%;

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.3;
  `}
`;
