import React from "react";
import styled from "styled-components";
import { LIGHT_GREY_1 } from "../colors";

const Wrapper = styled.div`
  a {
    color: ${LIGHT_GREY_1};
    text-decoration: none;
  }

  a:hover {
    cursor: pointer;
  }
`;

export default (props) => (
  <Wrapper>
    <a {...props} />
  </Wrapper>
);
