import React, { useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import queryString from "query-string";
import { Formik, useFormikContext } from "formik";
import { navigate } from "gatsby";
import styled from "styled-components";
import ContentBox from "../components/ContentBox";
import Form from "../components/Form";
import FormField from "../components/FormField";
import Label from "../components/Label";
import Input from "../components/Input";
import Button from "../components/buttons/Button";
import Link from "../components/Link";
import ActionBar from "../components/OldActionBar";
import DangerButton from "../components/buttons/DangerButton";
import Modal from "../components/Modal";
import FormError from "../components/FormError";
import PageWrapper from "../components/PageWrapper";
import parseAnswer from "../util/parseAnswer";
import Textarea from "../components/Textarea";
import DarkPageHeading from "../components/DarkPageHeading";

const GET_TIEBREAKER = gql`
  query GetTiebreaker($quizId: ID!) {
    tiebreaker: getTiebreaker(quizId: $quizId) {
      id
      text
      answer
      answerValue
      quiz {
        started
      }
    }
  }
`;

const SAVE_TIEBREAKER = gql`
  mutation SaveTiebreaker(
    $quizId: ID!
    $text: String!
    $answer: String!
    $answerValue: BigInt!
  ) {
    saveTiebreaker(
      quizId: $quizId
      text: $text
      answer: $answer
      answerValue: $answerValue
    ) {
      tiebreaker {
        id
      }
    }
  }
`;

const DELETE_TIEBREAKER = gql`
  mutation DeleteTiebreaker($quizId: ID!) {
    deleteTiebreaker(quizId: $quizId) {
      success
    }
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const QuizStartedMessage = styled.div`
  font-size: 18px;
  color: red;
  margin-bottom: 20px;
`;

const WideFormField = styled(FormField)`
  width: 600px;
`;

const TiebreakerEditor = ({
  eventId,
  quizId,
  tiebreakerId,
  originalValues,
  isStarted,
  onDelete,
}) => {
  const {
    values,
    touched,
    errors,
    handleChange,
    submitForm,
    isSubmitting,
  } = useFormikContext();
  const [showDeleteModal, setShowDeleteModal] = useState();
  const [isDeleting, setIsDeleting] = useState();

  const isChanged = () =>
    values.text !== originalValues.text ||
    values.answer !== originalValues.answer;

  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    await onDelete();
  };

  return (
    <ContentBox direction="column">
      <DarkPageHeading>
        {!tiebreakerId ? "Add a Tiebreaker" : "Edit Tiebreaker"}
      </DarkPageHeading>
      <FormWrapper>
        <Form>
          {isStarted && (
            <QuizStartedMessage>
              Tiebreaker is not editable since quiz has already started.
            </QuizStartedMessage>
          )}

          <WideFormField>
            <Label htmlFor="text">Text</Label>
            <Textarea
              type="text"
              name="text"
              autoComplete="off"
              value={values.text}
              onChange={handleChange}
            />
            {errors.text && touched.text && (
              <FormError>{errors.text}</FormError>
            )}
          </WideFormField>

          <WideFormField>
            <Label htmlFor="answer">Answer</Label>
            <Input
              type="text"
              name="answer"
              autoComplete="off"
              disabled={isStarted}
              value={values.answer}
              onChange={handleChange}
            />
            {errors.answer && touched.answer && (
              <FormError>{errors.answer}</FormError>
            )}
          </WideFormField>
        </Form>
      </FormWrapper>
      <ActionBar>
        <Button
          size="large"
          disabled={!isChanged() || isSubmitting}
          onClick={() => submitForm()}
        >
          {isSubmitting ? "Saving" : "Save Tiebreaker"}
        </Button>
        <Link
          to={
            eventId
              ? `/create-edit-event?eventId=${eventId}`
              : `/create-edit-quiz?quizId=${quizId}`
          }
        >
          <Button size="large">Close</Button>
        </Link>
        <DangerButton
          size="large"
          disabled={!tiebreakerId || isStarted}
          onClick={handleDeleteClick}
        >
          Delete Tiebreaker
        </DangerButton>
      </ActionBar>
      {showDeleteModal && (
        <Modal
          title="Delete Tiebreaker"
          text="Delete this tiebreaker and all the related questions?"
          buttons={
            <>
              <DangerButton
                size="large"
                disabled={isDeleting}
                onClick={handleConfirmDelete}
              >
                {isDeleting ? "Deleting" : "Delete"}
              </DangerButton>
              <Button size="large" onClick={handleCancelDelete}>
                Cancel
              </Button>
            </>
          }
        />
      )}
    </ContentBox>
  );
};

export default function CreateEditTiebreaker({ location }) {
  const { eventId, quizId, tiebreakerId } = queryString.parse(location.search);

  const { data, refetch } = useQuery(GET_TIEBREAKER, {
    variables: {
      quizId: quizId,
    },
    skip: !tiebreakerId,
  });

  const [saveTiebreaker] = useMutation(SAVE_TIEBREAKER);
  const [deleteTiebreaker] = useMutation(DELETE_TIEBREAKER);

  if (tiebreakerId && !data) {
    return null;
  }

  const originalValues = (data && data.tiebreaker) || {};

  const initialValues = {
    text: data && data.tiebreaker && data.tiebreaker.text,
    answer: data && data.tiebreaker && data.tiebreaker.answer,
  };

  const validate = (values) => {
    const errors = {};
    if (!values.text) {
      errors.text = "You must enter text for the tiebreaker";
    }
    if (!values.answer) {
      errors.answer = "You must enter an answer to the tiebreaker";
    } else if (isNaN(parseAnswer(values.answer))) {
      errors.answer = "You must enter a number";
    }
    return errors;
  };

  const handleSubmit = async (values) => {
    const result = await saveTiebreaker({
      variables: {
        quizId: quizId,
        text: values.text,
        answer: values.answer,
        answerValue: parseAnswer(values.answer),
      },
    });
    const newTiebreakerId = result.data.saveTiebreaker.tiebreaker.id;
    await navigate(
      `/create-edit-tiebreaker?eventId=${eventId}&quizId=${quizId}&tiebreakerId=${newTiebreakerId}`
    );
    await refetch({ quizId, tiebreakerId: newTiebreakerId });
  };

  const handleDelete = async () => {
    await deleteTiebreaker({
      variables: {
        quizId: quizId,
      },
    });
    if (eventId) {
      await navigate(`/create-edit-event?eventId=${eventId}`);
    } else {
      await navigate(`/create-edit-quiz?quizId=${quizId}`);
    }
  };

  return (
    <PageWrapper>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
      >
        <TiebreakerEditor
          eventId={eventId}
          quizId={quizId}
          tiebreakerId={tiebreakerId}
          originalValues={originalValues}
          isStarted={
            data &&
            data.tiebreaker &&
            data.tiebreaker.quiz &&
            data.tiebreaker.quiz.started
          }
          onDelete={handleDelete}
        />
      </Formik>
    </PageWrapper>
  );
}
