import React, { useContext, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";
import ContentBox from "./ContentBox";
import { Heading } from "grommet/es6";
import UserContext from "../contexts/UserContext";
import LightA from "./LightA";
import SubtleA from "./SubtleA";
import styled from "styled-components";
import DarkButton from "./buttons/DarkButton";
import { navigate } from "gatsby";
import Logo from "./Logo";
import getApiPrefix from "../util/getApiPrefix";

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    user: getCurrentUser {
      id
      username
      email
      isSuperuser
      isImpersonate
    }
  }
`;

const OurContentBox = styled.div`
  display: flex;
  width: 100%;
  padding: 0;
`;

const LogoLink = styled(SubtleA)`
  display: flex;
  align-items: center;
`;

const MenuItemContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin: 0 15px;
  }
`;

const UserName = styled.span``;

// const getLogInUrl = () => `${getPrefix()}/accounts/login/`;
const getLogOutUrl = () => `${getApiPrefix()}/accounts/logout/`;
// const getSignUpUrl = () => `${getPrefix()}/accounts/signup/`;
const getImpersonateUrl = () => `${getApiPrefix()}/impersonate/list/`;
const getStopImpersonateUrl = () => `${getApiPrefix()}/impersonate/stop/`;

export default () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const { loading, data } = useQuery(GET_CURRENT_USER);

  useEffect(() => {
    if (data) {
      setCurrentUser(data.user);
    }
  }, [data]);

  const handleLogoClick = async () => {
    await navigate("/");
  };

  const handleLogOutClick = () => (window.location.href = getLogOutUrl());
  // const handleSignUpClick = () => (window.location.href = getSignUpUrl());

  const loggedInButtons = !loading && currentUser;
  // const loggedOutButtons = !loading && !currentUser && !quiz;

  return (
    <ContentBox>
      <OurContentBox>
        <Heading level="3" margin="none" onClick={handleLogoClick}>
          <LogoLink>
            <Logo />
          </LogoLink>
        </Heading>
        <MenuItemContainer>
          {loggedInButtons && (
            <>
              {!currentUser.isSuperuser && !currentUser.isImpersonate && (
                <UserName>User: {data.user.username}</UserName>
              )}
              {currentUser.isSuperuser && !currentUser.isImpersonate && (
                <LightA href={getImpersonateUrl()}>
                  User: {data.user.username}
                </LightA>
              )}
              {currentUser.isImpersonate && (
                <LightA href={getStopImpersonateUrl()}>
                  User: {data.user.username}
                </LightA>
              )}
              <DarkButton onClick={handleLogOutClick}>Log out</DarkButton>
            </>
          )}
          {/*{loggedOutButtons && (*/}
          {/*  <>*/}
          {/*    <LightA href={getLogInUrl()}>Log in</LightA>*/}
          {/*    <DarkButton onClick={handleSignUpClick}>Sign Up</DarkButton>*/}
          {/*  </>*/}
          {/*)}*/}
        </MenuItemContainer>
      </OurContentBox>
    </ContentBox>
  );
};
