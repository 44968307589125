import styled from "styled-components";
import { ACCENT_1, WHITE } from "../colors";

export default styled.input`
  display: block;
  box-sizing: border-box;
  font-family: inherit;
  -webkit-appearance: none;
  background-color: ${WHITE};
  color: inherit;
  padding: 11px;
  font-weight: 600;
  margin: 0;
  border: 1px solid rgba(0, 0, 0, 0.33);
  border-radius: 4px;
  width: 100%;
  font-size: ${({ size }) => (size === "large" ? 22 : 18)}px;
  line-height: ${({ size }) => (size === "large" ? 28 : 24)}px;
  max-width: 400px;
  min-width: 300px;

  :focus {
    outline: none;
    box-shadow: 0 0 2px 2px ${ACCENT_1};
  }

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.3;
  `}
`;
