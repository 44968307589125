import React from "react";
import styled from "styled-components";
import { LIGHT_GREY_4, MUTED_TEXT, REGULAR_TEXT, WHITE } from "../colors";

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
`;

const ModalContent = styled.div`
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  max-height: 100%;
  background-color: ${WHITE};
  border: 1px solid ${LIGHT_GREY_4};
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled.div`
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 30px;
  text-align: center;
  color: ${REGULAR_TEXT};
`;

const ModalBody = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

const ModalText = styled.div`
  font-size: 18px;
  color: ${MUTED_TEXT};
  text-align: center;
  margin-bottom: 20px;
  line-height: 150%;
`;

const ModalButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    margin: 0 10px;
  }
`;

export default ({ title, text, text2, body, buttons }) => (
  <ModalBackground>
    <ModalContent data-cy="modal">
      <ModalTitle data-cy="modal-title">{title}</ModalTitle>
      <ModalBody>
        {text && <ModalText>{text}</ModalText>}
        {text2 && <ModalText>{text2}</ModalText>}
        {body}
      </ModalBody>
      <ModalButtonContainer>{buttons}</ModalButtonContainer>
    </ModalContent>
  </ModalBackground>
);
