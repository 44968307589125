import React from "react";
import styled from "styled-components";
import { LIGHT_GREY_2 } from "../colors";

const Spacer = styled.div`
  height: 100px;
`;

const ActionBarWrapper = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: ${LIGHT_GREY_2};
  height: 100px;

  button {
    margin: 0 10px;
  }
`;

export default (props) => (
  <>
    <Spacer />
    <ActionBarWrapper {...props} />
  </>
);
