import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { aStyle, aHoverStyle } from "./A";

const LinkWrapper = styled.span`
  a {
    ${aStyle}
  }

  a:hover {
    ${aHoverStyle}
  }
`;

export default (props) => (
  <LinkWrapper>
    <Link {...props} />
  </LinkWrapper>
);
